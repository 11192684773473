import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { canNavigate } from '@/libs/acl/routeProtection'
import { firebase } from '../firebase'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/TermsAndPolicy.vue'),
    meta: {
      layout: 'full',
      allowed: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      layout: 'full',
      allowed: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/Signup.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/Landing.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password-confirm',
    name: 'reset-password-confirm',
    component: () => import('@/views/auth/ResetPasswordConfirm.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register-verify-email',
    name: 'register-verify-email',
    component: () => import('@/views/auth/RegisterVerifyEmail.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/links',
    name: 'links',
    component: () => import('@/views/SocialLinks.vue'),
    meta: {
      layout: 'full',
      allowed: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      allowed: true,
    },
  },
]

const routesWithoutStores = [
  {
    path: '/store-connect',
    name: 'store-connect',
    component: () => import('@/views/StoreConnect.vue'),
    meta: {
      pageTitle: 'StoreConnect',
      requiresAuth: true,
      noStore: true,
    },
  },
]

const routesWithStores = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      requiresAuth: true,
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/Customers.vue'),
    meta: {
      pageTitle: 'Customers',
      requiresAuth: true,
      resource: 'customers',
      action: 'read',
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/Admin.vue'),
    meta: {
      requiresAdmin: true,
      pageTitle: 'Admin',
      requiresAuth: true,
    },
  },
  {
    path: '/customer-register',
    name: 'customer-register',
    component: () => import('@/views/CustomerRegister.vue'),
    meta: {
      pageTitle: 'Customer Register',
      requiresAuth: true,
      resource: 'customers',
      action: 'create',
    },
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/Customer.vue'),
    meta: {
      pageTitle: 'Customer',
      requiresAuth: true,
      resource: 'customers',
      action: 'read',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      pageTitle: 'FaQ',
      requiresAuth: true,
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/Transactions.vue'),
    meta: {
      pageTitle: 'Transactions',
      requiresAuth: true,
      resource: 'transactions',
      action: 'read',
    },
  },
  {
    path: '/collaterals',
    name: 'collaterals',
    component: () => import('@/views/Collaterals.vue'),
    meta: {
      pageTitle: 'Collaterals',
      requiresAuth: true,
      resource: 'collaterals',
      action: 'read',
    },
  },
  {
    path: '/collateral/create',
    name: 'collateral-create',
    component: () => import('@/views/collateral/ProductForm.vue'),
    meta: {
      pageTitle: 'Collateral',
      requiresAuth: true,
      resource: 'collaterals',
      action: 'read',
    },
  },
  {
    path: '/collateral/:pid',
    name: 'collateral',
    component: () => import('@/views/collateral/Collateral.vue'),
    meta: {
      pageTitle: 'Collateral',
      requiresAuth: true,
      resource: 'collaterals',
      action: 'read',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/Reports.vue'),
    meta: {
      pageTitle: 'Reports',
      requiresAuth: true,
      resource: 'reports',
      action: 'read',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings.vue'),
    meta: {
      pageTitle: 'Settings',
      requiresAuth: true,
      noStore: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [...baseRoutes, ...routesWithStores, ...routesWithoutStores],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const requiresACL = to.matched.some(x => x.meta.resource)
  const allowed = to.matched.some(x => x.meta.allowed)

  const noStore = to.matched.some(x => x.meta.noStore)
  const { currentUser } = firebase.auth

  // const { stores } = store.state.app
  const { user } = store.state.users

  if (requiresAuth && !currentUser) next({ path: '/login', query: { redirect: to.fullPath } })
  else if (requiresAdmin) {
    firebase.auth.currentUser.getIdTokenResult(true)
      .then(idTokenResult => {
        if (idTokenResult.claims.isAdmin) {
          next()
        } else {
          next({ name: 'dashboard' })
        }
      })
      .catch(() => {
        next({ name: 'dashboard' })
      })
  } else if (requiresAuth && !noStore && ((user && !user.sid) || !user)) next('/store-connect')
  else if (allowed) next()
  else if (requiresACL && !canNavigate(to)) next({ name: 'not-authorized' })
  else if (to.name === 'store-connect' && user && user.sid) next({ name: 'dashboard' })
  else if (!requiresAuth && !currentUser && baseRoutes.filter(item => item.name === to.name).length > 0) next()
  else if (!requiresAuth && !currentUser) next({ name: 'landing' })
  else if (!requiresAuth && currentUser && to.name !== 'not-authorized') next({ name: 'dashboard' })
  else next()
})

export default router
