import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import transactions from './module/transactions'
import users from './module/users'
import customers from './module/customers'
import collaterals from './module/collaterals'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    transactions,
    users,
    customers,
    collaterals,
  },
  strict: process.env.DEV,
})
