export const initialAbility = [
  {
    action: ['read'],
    subject: 'auth',
  },
]

export const basicRestrictAbility = [
  {
    action: ['read'],
    subject: 'dashboard',
  },
  {
    action: ['read'],
    subject: 'customers',
  },
  {
    action: ['read'],
    subject: 'transactions',
  },
  {
    action: ['read'],
    subject: 'reports',
  },
  {
    action: ['read'],
    subject: 'store',
  },
]

export const restrictLoanAbility = [
  ...basicRestrictAbility,
]

export const restrictCollateralAbility = [
  ...basicRestrictAbility,
  {
    action: ['read'],
    subject: 'collaterals',
  },
]

export const restrictTrialAbility = [
  ...restrictLoanAbility,
  ...restrictCollateralAbility,
]

export const restrictAbility = paymentPackage => {
  const abilities = {
    init: initialAbility,
    trial: restrictTrialAbility,
    loan: restrictLoanAbility,
    collateral: restrictCollateralAbility,
  }

  const ability = []
  paymentPackage.forEach(pk => {
    ability.push(...abilities[pk])
  })

  return [...new Map(ability.map(item => [item.subject, item])).values()]
}

export const basicManageAbility = [
  {
    action: ['manage'],
    subject: 'dashboard',
  },
  {
    action: ['manage'],
    subject: 'customers',
  },
  {
    action: ['manage'],
    subject: 'transactions',
  },
  {
    action: ['manage'],
    subject: 'reports',
  },
  {
    action: ['manage'],
    subject: 'store',
  },
]

export const loanManageAbility = [
  ...basicManageAbility,
]

export const collateralManageAbility = [
  ...basicManageAbility,
  {
    action: ['manage'],
    subject: 'collaterals',
  },
]

export const trialManageAbility = [
  ...loanManageAbility,
  ...collateralManageAbility,
]

export const manageAbility = paymentPackage => {
  const abilities = {
    trial: trialManageAbility,
    loan: loanManageAbility,
    collateral: collateralManageAbility,
  }

  const ability = []
  paymentPackage.forEach(pk => {
    ability.push(...abilities[pk])
  })

  return [...new Map(ability.map(item => [item.subject, item])).values()]
}

export const _ = undefined
