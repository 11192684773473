/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import {
  usersCollection, storesCollection, firebase, auth, firebaseFunction, applicationsCollection,
} from '@/firebase'
import { set } from 'vuex-intern'
import Vue from 'vue'
import axios from 'axios'
import ability from '@/libs/acl/ability'
import { restrictAbility, manageAbility } from '@/libs/acl/config'
import i18n from '@/libs/i18n'
import { oneDateRange, isEmpty } from '../util'

const state = {
  user: null,
  stores: null,
  groups: [],
  currentStore: null,
  dailyNotes: {},
  storeLogs: null,
  storeUsers: {},
}

const getters = {
  getUser: state => state.user,
  getCurrentStore: state => state.currentStore,
  getStoreUsers: state => state.storeUsers,
  getGroups: state => state.groups,
  getDailyNotes: state => date => state.dailyNotes[date] || [],
  getStoreLogs: state => state.storeLogs,
}

const mutations = {
  setStoreLogs: set('storeLogs'),
  setStoreUsers: set('storeUsers'),
  setDailyNotes: (state, { date, data }) => {
    const formattedDate = Vue.moment(date).format('YYYY-MM-DD')
    Vue.set(state.dailyNotes, formattedDate, data.sort((a, b) => a.createdAt - b.createdAt))
  },
  extendsetDailyNotes: (state, { date, data }) => {
    const formattedDate = Vue.moment(date).format('YYYY-MM-DD')
    state.dailyNotes[formattedDate].push(data)
  },
  setUsersState: (state, { name, data }) => {
    Vue.set(state, name, data)
  },
  setUser: set('user'),
  updateUser: (state, { field, value }) => {
    state.user[field] = value
  },
  setStores: set('stores'),
  setCurrentStore: set('currentStore'),
  setGroups: (state, { group, action }) => {
    if (action === 'set' && Array.isArray(group)) {
      state.groups = group
    } else {
      state.groups.push(group)
    }
  },
}

const actions = {
  fetchUserInfo: ({
    commit, dispatch, state, rootState,
  }, { uid }) => new Promise((resolve, reject) => {
    if (!state.user) {
      firebase.auth.currentUser.getIdTokenResult(true)
        .then(async idTokenResult => {
          let sid = idTokenResult.claims.store_id
          let storeId = null
          const stores = await dispatch('fetchUserStores', { uid })
          let currentStore = []
          let pastDue = false
          if (stores && stores.length > 0) {
            currentStore = stores.find(store => store.sid === sid)
            if (!currentStore) [currentStore] = stores
            storeId = currentStore.sid
            if (!currentStore.billing || Vue.moment(currentStore.billing.paymentDate).diff(Vue.moment(), 'd') < -6) {
              ability.update(restrictAbility(currentStore.billing.paymentPackage))
              pastDue = true
            }
            commit('setGroups', { group: currentStore.groups, action: 'set' })
            commit('setCurrentStore', currentStore)
          }

          if (sid !== storeId) {
            sid = storeId
            const addStoreId = firebaseFunction.httpsCallable('addStoreId')
            addStoreId({ uid, store_id: sid })
          }

          usersCollection.doc(uid).onSnapshot(async snapshot => {
            if (snapshot.exists) {
              const user = snapshot.data()
              if (user.skin && user.skin !== rootState.appConfig.layout.skin && ['dark', 'light', 'custom-a'].includes(user.skin)) {
                commit('appConfig/UPDATE_SKIN', user.skin)
              }

              if (user.language) {
                i18n.locale = user.language
              }

              if (uid === currentStore.uid) {
                user.ability = manageAbility(currentStore.billing.paymentPackage)
              } else if (user.roles && sid && user.roles[sid]) {
                user.ability = currentStore.roles.filter(r => r.roleName === user.roles[sid][0])[0].ability
              } else {
                user.ability = restrictAbility(['init'])
              }

              if (!pastDue) {
                ability.update(user.ability)
                localStorage.setItem('userData', JSON.stringify(user))
              }
              commit('setUsersState', { name: 'user', data: { ...user, sid } })
              resolve({ ...user, sid })
            } else {
              resolve(null)
            }
          }, err => {
            reject(err)
          })
        }).catch(error => {
          reject(error)
        })
    } else {
      resolve(state.user)
    }
  }),
  fetchStoreUsers: async ({ commit, state }) => {
    const storeUsers = {}
    const userPromises = []
    if (!state.storeUsers || isEmpty(state.storeUsers)) {
      state.currentStore.users.forEach(user => {
        const response = usersCollection.doc(user).get().then(u => {
          if (u.exists) {
            storeUsers[u.id] = u.data()
          }
        })
        userPromises.push(response)
      })
      await Promise.all(userPromises)
      commit('setStoreUsers', storeUsers)
    }
  },
  fetchUserStores: async ({ commit }, { uid }) => new Promise((resolve, reject) => {
    storesCollection.where('users', 'array-contains', uid).onSnapshot(snapshot => {
      const stores = []
      if (snapshot.empty) {
        resolve(stores)
      } else {
        snapshot.forEach(doc => {
          const store = doc.data()
          if (store.billing && store.billing.paymentDate) {
            store.billing.paymentDate = store.billing.paymentDate.toDate()
          }
          stores.push({ ...store, sid: doc.id })
        })

        commit('setStores', stores)
        resolve(stores)
      }
    }, err => {
      reject(err)
    })
  }),
  deleteStoreGroup: async ({ commit, dispatch }, {
    uid, sid, name, groups,
  }) => new Promise((resolve, reject) => {
    if (uid && sid && name && groups) {
      const removeIndex = groups.map(item => item.groupName).indexOf(name)
      groups.splice(removeIndex, 1)
      storesCollection.doc(sid).set({
        groups,
      }, { merge: true })
        .then(() => {
          commit('setGroups', { group: groups, action: 'set' })
          dispatch('logUser', { action: 'delete_store_group', actionId: name, type: 'stores' })
          resolve(groups)
        })
        .catch(error => {
          reject(error)
        })
    } else {
      reject()
    }
  }),
  createStoreGroup: async ({ commit, dispatch }, {
    uid, sid, name, desc,
  }) => new Promise((resolve, reject) => {
    if (uid && sid && name && desc) {
      const group = {
        groupName: name,
        groupDesc: desc,
      }
      storesCollection.doc(sid).set({
        groups: firebase.firebase.firestore.FieldValue.arrayUnion({
          ...group,
        }),
      }, { merge: true })
      commit('setGroups', { group })
      dispatch('logUser', { action: 'create_store_group', actionId: name, type: 'stores' })
      resolve()
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  fetchNotes: async ({ commit, state }, { date, sid }) => new Promise((resolve, reject) => {
    if (sid && date) {
      if (state.dailyNotes[date]) {
        resolve(state.dailyNotes[date])
      } else {
        const { startDate, endDate } = oneDateRange(date)
        storesCollection.doc(sid).collection('notes')
          .where('createdAt', '>=', startDate)
          .where('createdAt', '<', endDate)
          .onSnapshot(async snapshot => {
            if (snapshot.empty) {
              commit('setDailyNotes', { date, data: [] })
              resolve([])
            } else {
              const notes = []
              snapshot.forEach(doc => {
                notes.push({
                  ...doc.data(), createdAt: doc.data().createdAt.toDate(), type: 'note', nid: doc.id,
                })
              })
              commit('setDailyNotes', { date, data: notes })
              resolve(notes)
            }
          }, err => {
            reject(err)
          })
      }
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  createNote: async ({ dispatch }, {
    uid, sid, note, userName, dateTime,
  }) => {
    if (uid && sid && note && userName) {
      const createdAt = new Date(dateTime)
      const data = {
        uid,
        sid,
        note,
        userName,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }
      const res = await storesCollection.doc(sid).collection('notes').add(data)
      dispatch('logUser', {
        action: 'create_note', actionId: res.id, data, type: 'stores',
      })
    } else {
      throw new Error('Missing Params')
    }
  },
  deleteNote: async ({ dispatch }, { nid, sid, data }) => {
    if (nid && sid) {
      await storesCollection.doc(sid).collection('notes').doc(nid).delete()
        .then(async () => {
          dispatch('logUser', {
            action: 'delete_note', actionId: nid, data, type: 'stores',
          })
        })
    }
  },
  logUser: async (ctx, params) => {
    const {
      action, actionId, data, type,
    } = params
    await firebase.auth.currentUser.getIdTokenResult(true)
      .then(async idTokenResult => {
        const sid = params.sid || idTokenResult.claims.store_id
        const uid = params.uid || idTokenResult.uid || idTokenResult.claims.user_id
        if (sid && uid) {
          const response = await axios.get('https://api.ipify.org?format=json')
          const userIp = response.data.ip
          const update = {
            type,
            action,
            actionId,
            uid,
            sid,
            createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
          }
          if (data) update.data = data
          if (userIp) update.userIp = userIp
          await firebase.db.collection('stores').doc(sid)
            .collection('logs')
            .add(update)
        }
      })
  },
  fetchStoreUserLogs: async ({ commit, state }) => {
    const passTwoWeeks = new Date(Vue.moment().subtract(7, 'd'))
    firebase.db.collection('stores').doc(state.currentStore.sid)
      .collection('logs')
      .where('createdAt', '>=', passTwoWeeks)
      .orderBy('createdAt', 'desc')
      .limit(50)
      .get()
      .then(logs => {
        const log = []
        if (!logs.empty) {
          logs.forEach(doc => {
            log.push({
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
            })
          })
        }
        commit('setStoreLogs', log)
      })
  },
  uploadUserAvatar: async ({ commit }, { uid, file }) => new Promise((resolve, reject) => {
    if (uid && file) {
      const extension = file.type.replace('image/', '')
      const metadata = {
        customMetadata: {
          id: uid,
        },
        contentType: file.type,
      }
      const storageRef = firebase.storage.ref()
      const mountainsRef = storageRef.child(`usersAvatar/${uid}.${extension}`)

      mountainsRef.put(file, metadata).then(async snapshot => {
        if (snapshot.state === 'success') {
          const urlRef = storageRef.child(`usersAvatar/${uid}.${extension}`)
          urlRef.getDownloadURL().then(url => {
            commit('updateUser', { field: 'photoURL', value: url })
            usersCollection.doc(uid).update({
              photoURL: url,
            })

            auth.doPhotoURLChange(url)
            resolve(url)
          })
        } else {
          resolve(null)
        }
      }).catch(error => {
        reject(error)
      })
    }
  }),
  updateUserInfo: async ({ state, commit }, { skin, language }) => new Promise((resolve, reject) => {
    const { uid } = state.user
    if (uid) {
      const update = {}
      if (skin && ['dark', 'light', 'custom-a'].includes(skin)) {
        update.skin = skin
      } else {
        reject(new Error('Missing Params'))
      }

      if (language && ['en', 'tw'].includes(language)) {
        update.language = language
      } else {
        reject(new Error('Missing Params'))
      }

      if (!isEmpty(update)) {
        usersCollection.doc(uid).update(update).then(() => {
          commit('appConfig/UPDATE_SKIN', skin)
        }).catch(error => {
          reject(error)
        })
      }
    } else {
      reject(new Error('User does not exist'))
    }
  }),
  updateUserName: async ({ state }, { name }) => new Promise((resolve, reject) => {
    const { uid } = state.user
    if (uid && name) {
      usersCollection.doc(uid).update({
        displayName: name,
      }).then(() => {
        auth.doUpdateDisplayName(name)
      }).catch(error => {
        reject(error)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  updatePassword: async (ctx, { oldPassword, newPassword }) => new Promise((resolve, reject) => {
    if (oldPassword && newPassword) {
      const { currentUser } = firebase.firebase.auth()
      const credential = firebase.firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        oldPassword,
      )

      currentUser.reauthenticateWithCredential(credential).then(() => {
        auth.doPasswordChange(newPassword)
      }).catch(() => {
        reject(new Error('wrong password'))
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  deleteUserFromStore: async ({ dispatch }, { user, sid }) => new Promise((resolve, reject) => {
    if (user && sid) {
      const batch = firebase.db.batch()
      const storesRef = storesCollection.doc(sid)
      batch.update(storesRef, {
        users: firebase.firebase.firestore.FieldValue.arrayRemove(user.uid),
      })
      const usersRef = usersCollection.doc(user.uid)
      const { roles } = user
      delete roles[sid]
      batch.update(usersRef, {
        roles,
      })

      batch.commit().then(() => {
        dispatch('logUser', { action: 'delete_user_store', actionId: user.uid, type: 'stores' })
        resolve()
      }).catch(error => {
        reject(error)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  storeUpdateRole: async ({ dispatch }, { user, sid, roleName }) => new Promise((resolve, reject) => {
    if (sid && roleName && user) {
      const { roles } = user
      roles[sid] = [roleName]
      usersCollection.doc(user.uid).update({ roles }).then(() => {
        dispatch('logUser', {
          action: 'update_user_role', actionId: user.uid, data: roleName, type: 'users',
        })
        resolve()
      }).catch(error => {
        reject(error)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  storeRolesAction: async ({ dispatch, state }, { role, action }) => new Promise((resolve, reject) => {
    if (state.currentStore && role && role.roleName && action) {
      let { roles } = state.currentStore
      const { sid } = state.currentStore
      if (action === 'update') {
        const roleIndex = roles.map(item => item.roleName).indexOf(role.roleName)
        roles[roleIndex] = role
      } else if (action === 'add') {
        roles.push(role)
      } else if (action === 'delete') {
        const roleIndex = roles.map(item => item.roleName).indexOf(role.roleName)
        roles.splice(roleIndex, 1)
      }

      roles = roles.filter(r => r.roleName)
      storesCollection.doc(sid).update({ roles }).then(() => {
        dispatch('logUser', { action: `${action}_store_roles`, actionId: role.roleName, type: 'stores' })
        resolve(roles)
      }).catch(error => {
        reject(error)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  deleteAllStoreData: async ({ dispatch, rootState }) => new Promise((resolve, reject) => {
    const { sid } = rootState.users.currentStore
    const suid = rootState.users.currentStore.uid
    const { uid } = rootState.users.user
    if (sid && uid === suid) {
      firebaseFunction.useEmulator('localhost', 5001)
      const deleteStoreData = firebaseFunction.httpsCallable('deleteStoreData')
      deleteStoreData({ sid }).then(() => {
        dispatch('logUser', { action: 'delete_store_data', actionId: sid, type: 'stores' })
        resolve()
      }).catch(error => {
        reject(error)
      })
    } else {
      let message = `Missing Params, sid: ${sid}`
      if (uid === suid) message = 'Must be an owner'
      reject(new Error(message))
    }
  }),
  addToApplications: async ({ rootState }, {
    storeName, storeType, taxId, contactNumber, contactName,
  }) => new Promise((resolve, reject) => {
    const { uid } = rootState.users.user
    if (uid && storeName && storeType && ((storeName === 'registered_company' && taxId) || storeName !== 'registered_company')) {
      const batch = firebase.db.batch()
      const applicationRef = applicationsCollection.doc()
      const usersRef = usersCollection.doc(uid)
      batch.set(applicationRef, {
        storeName,
        storeType,
        taxId,
        uid,
        contactNumber,
        contactName,
        createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      })

      batch.update(usersRef, { applications: [applicationRef.id] })
      batch.commit().then(() => {
        resolve()
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
}

export default {
  state,
  mutations,
  getters,
  actions,
}
