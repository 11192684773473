import * as auth from './auth'
import * as db from './db'
import * as firebase from './firebase'
import { databaseInit } from './init'
import 'firebase/functions'

const usersCollection = firebase.db.collection('users')
const storesCollection = firebase.db.collection('stores')
const customersCollection = firebase.db.collection('customers')
const transCollection = firebase.db.collection('transactions')
const reportsCollection = firebase.db.collection('reports')
const collateralsCollection = firebase.db.collection('collaterals')
const applicationsCollection = firebase.db.collection('applications')

const firebaseFunction = firebase.app.functions('asia-northeast1')

// firebaseFunction.useFunctionsEmulator('http://localhost:5001')

export {
  auth, db, firebase, databaseInit, usersCollection, storesCollection, transCollection, customersCollection, reportsCollection, firebaseFunction, collateralsCollection, applicationsCollection,
}
