/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import { customersCollection, firebase } from '@/firebase'
import Vue from 'vue'
import _ from 'lodash'
import svgToMiniDataURI from 'mini-svg-data-uri'
import { isEmpty } from '../util'

const state = {
  dailyDueDateCustomers: {},
  dailyPenaltyCustomers: {},
  currentCustomer: null,
}

const getters = {
  // getCustomers: state => state.customers,
  getCurrentCustomer: state => state.currentCustomer,
  getAllDailyDueDateCustomers: state => state.dailyDueDateCustomers,
  getDailyDueDateCustomers: state => date => state.dailyDueDateCustomers[date],
  getDailyPenaltyCustomers: state => date => state.dailyPenaltyCustomers[date],
}

const mutations = {
  setCurrentCustomer: (state, value) => {
    Vue.set(state, 'currentCustomer', value)
  },
  replaceObjectFields: (state, { name, data, check }) => {
    if (!check || (state[name] && state[name][check.key] === check.value)) {
      Object.entries(data).forEach(([key, value]) => {
        state[name][key] = value
      })
      state[name] = { ...state[name] }
    }
  },
  removeFromCustomers: (state, {
    name, date, key, value,
  }) => {
    if (date) {
      const formattedKey = Vue.moment(date).format('YYYY-MM-DD')
      if (state[name][formattedKey]) {
        const removeIndex = state[name][formattedKey].map(item => item[key]).indexOf(value)
        state[name][formattedKey].splice(removeIndex, 1)
      }
    } else if (state[name]) {
      const removeIndex = state[name].map(item => item[key]).indexOf(value)
      state[name].splice(removeIndex, 1)
    }
  },
  setCustomersState: (state, {
    name, key, data, isDate,
  }) => {
    if (key) {
      const formattedKey = (isDate) ? Vue.moment(key).format('YYYY-MM-DD') : key
      Vue.set(state[name], formattedKey, data)
    } else {
      Vue.set(state, name, data)
    }
  },
  extendCustomerState: (state, {
    name, key, data, isDate,
  }) => {
    if (key) {
      const formattedKey = (isDate) ? Vue.moment(key).format('YYYY-MM-DD') : key
      state[name][formattedKey].push(data)
    } else {
      state[name].push(data)
    }
  },
  replaceCustomerStateFields: (state, {
    name, key, data, isDate, cid,
  }) => {
    if (key) {
      const formattedKey = (isDate) ? Vue.moment(key).format('YYYY-MM-DD') : key
      if (state[name][formattedKey]) {
        const updateIndex = state[name][formattedKey].map(item => item.cid).indexOf(cid)
        if (updateIndex >= 0) {
          Vue.set(state[name][formattedKey], updateIndex, _.merge({}, state[name][formattedKey][updateIndex], data))
        }
      }
    } else {
      const updateIndex = state[name].map(item => item.cid).indexOf(cid)
      if (updateIndex >= 0) {
        state[name][updateIndex] = _.merge({}, state[name][updateIndex], data)
      }
    }
  },
}

const actions = {
  fetchCustomer: async ({ commit, state }, { cid }) => new Promise((resolve, reject) => {
    if (cid) {
      if (state.currentCustomer && state.currentCustomer.cid === cid) {
        resolve(state.currentCustomer)
      } else if (state.customers && state.customers.length > 0) {
        commit('setCurrentCustomer', state.customers.find(c => c.cid === cid))
        resolve(state.customers.find(c => c.cid === cid))
      } else {
        customersCollection.doc(cid).get()
          .then(async snapshot => {
            if (snapshot.exists) {
              const customer = snapshot.data()
              if (customer.type !== 'deleted') {
                customer.loan.dueDateTime = (customer.loan.dueDateTime && customer.loan.dueDateTime !== '') ? customer.loan.dueDateTime.toDate() : null
                customer.loanHistories = customer.loanHistories.map(loan => ({ ...loan, loanDateTime: loan.loanDateTime.toDate() }))
                commit('setCurrentCustomer', { ...customer, cid: snapshot.id })
                resolve({ ...customer, cid: snapshot.id })
                const run = false
                if (run) {
                  let avatar = ''
                  await fetch(`https://api.dicebear.com/7.x/micah/svg?seed=${snapshot.id}`, { mode: 'cors' })
                    .then(respond => respond.text())
                    .then(svg => {
                      avatar = svgToMiniDataURI(svg)
                    })
                  const fileName = `avatarImages/${snapshot.id}.svg`
                  const storageRef = firebase.storage.ref()
                  const mountainsRef = storageRef.child(fileName)

                  await mountainsRef.putString(avatar, 'data_url')
                  const urlRef = storageRef.child(fileName)
                  const tokenUrl = await urlRef.getDownloadURL().then(url => url)
                  firebase.db.collection('customers').doc(snapshot.id).update({ photoURL: tokenUrl })
                }
              } else {
                resolve(null)
              }
            } else {
              resolve(null)
            }
          })
          .catch(error => {
            reject(error)
          })
      }
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  updateCustomerNote: async ({ dispatch }, { cid, note }) => new Promise((resolve, reject) => {
    if (cid && (note || note === '')) {
      customersCollection.doc(cid).update({
        'debtInfo.note': note,
      }).then(() => {
        dispatch('logUser', {
          action: 'update_customer_note', actionId: cid, data: note, type: 'customers',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  fetchCustomers: async ({ commit, rootState, state }, params) => new Promise((resolve, reject) => {
    const {
      sid, field, operator, date, status,
    } = params
    let { value } = params

    if (sid && date) {
      const formattedKey = Vue.moment(date).format('YYYY-MM-DD')
      if (!isEmpty(state.dailyDueDateCustomers[formattedKey])) {
        resolve()
      } else {
        const list = status || rootState.users.currentStore.display.dashboard || ['processing', 'observed', 'bad_debt', 'closed', 'active', 'inactive', 'dishonored']

        let query = customersCollection.where('sid', '==', sid).where('status', 'in', list)
        if (value === 'today') {
          const endDate = new Date()
          endDate.setHours(0, 0, 0, 0)
          endDate.setDate(endDate.getDate() + 1)
          value = endDate
        } else if (Vue.moment(value, 'YYYY-MM-DD', true).isValid()) {
          const start = new Date(Vue.moment(value, 'YYYY-MM-DD 00:00:00'))
          value = new Date(Vue.moment(value, 'YYYY-MM-DD 00:00:00').add(1, 'd'))
          if (field !== 'loan.dueDateTime') query = query.where(field, '>=', start)
        }

        if (field && value !== undefined && value !== null && operator) query = query.where(field, operator, value).where(field, '!=', '')
        query.onSnapshot(snapshot => {
          const customer = []
          let name = 'customers'
          if (field === 'loan.dueDateTime') name = 'dailyDueDateCustomers'
          if (field === 'loan.penalty') name = 'dailyPenaltyCustomers'

          if (!snapshot.empty) {
            snapshot.forEach(doc => {
              const d = doc.data()
              if ((field === 'loan.dueDateTime' && d.loan.penalty <= 0) || field !== 'loan.dueDateTime') {
                if (d.loan.dueDateTime && d.loan.dueDateTime !== '') d.loan.dueDateTime = d.loan.dueDateTime.toDate()
                customer.push({ ...d, cid: doc.id })
              }
            })
          }
          commit('setCustomersState', {
            name, data: customer, key: date, isDate: true,
          })
          resolve()
        }, err => {
          reject(err)
        })
      }
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  fixUserLoanDueDate: () => {
    customersCollection.get().then(snapshot => {
      if (!snapshot.empty) {
        snapshot.forEach(cs => {
          const c = cs.data()
          if (c.loanHistories && c.loanHistories.length > 0) {
            let update = false
            const { loan } = c
            c.loanHistories.forEach(lh => {
              if (lh.type === 'created') {
                if (c.loan.loanDateTime === '') {
                  loan.loanDateTime = lh.loanDateTime
                  update = true
                }
              }
            })
            if (update) {
              customersCollection.doc(cs.id).update({ loan })
            }
          }
        })
      }
    })
  },
  deleteCustomer: async ({ commit, dispatch }, customer) => {
    await customersCollection.doc(customer.cid).delete().then(async () => {
      await dispatch('deleteCustomerAvatar', customer.photoName)
      commit('setCurrentCustomer', null)
      dispatch('logUser', {
        action: 'delete_customer', actionId: customer.cid, data: customer, type: 'customers',
      })
    })
  },
  deleteCustomerAvatar: async (ctx, fileName) => {
    const storageRef = firebase.storage.ref()
    const desertRef = storageRef.child(`avatarImages/${fileName}`)
    await desertRef.delete()
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
