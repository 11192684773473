import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
// import VConsole from 'vconsole'
import VueGtag from 'vue-gtag'

import { firebase } from './firebase'
import '@/libs/acl'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueGtag, {
  config: { id: 'G-PLRXMX74FH' },
}, router)

Vue.config.productionTip = false

Vue.use(require('vue-moment'))

Vue.mixin({
  methods: {
    numberWithCommas(x) {
      if (x) {
        const number = +x
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
})

let app
firebase.auth.onAuthStateChanged(async user => {
  if (user) {
    await store.dispatch('fetchUserInfo', user)
  }

  if (!app) {
    app = new Vue({
      store,
      router,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
})

/* if (process.env.NODE_ENV === 'development') {
  App.vConsole = new VConsole()
} */
