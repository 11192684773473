/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// import { customersCollection, firebase } from '@/firebase'
/* import Vue from 'vue'
import { isEmpty } from '../util' */
import {
  firebase, collateralsCollection,
} from '@/firebase'
import { set, findByKey } from 'vuex-intern'

/* "典當者" 英文翻譯：   pledger

"在抵押中, 在典當中" 英文翻譯：   in pledge

Pawn Ticket
Consignment Sale

After getting his paycheck, Kevin redeemed his gold watch from the pawnshop.
凱文拿到薪水之後，就去當舖把金錶贖回。 */

const state = {
  collateralConditions: ['new', 'used'],
  collaterals: [],
  collateral: null,
  collateralStatus: [
    {
      value: 'observed',
      key: 'observed',
      name: 'observed',
      type: 'warning',
    },
    {
      value: 'in_pledge',
      key: 'closed',
      name: 'closed',
      type: 'primary',
    },
    {
      value: 'redeemed',
      key: 'redeemed',
      name: 'redeemed',
      type: 'success',
    },
    {
      value: 'foreclosure',
      key: 'foreclosure',
      name: 'foreclosure',
      type: 'danger',
    },
    {
      value: 'outright_sale',
      key: 'outright_sale',
      name: 'outright sale',
      type: 'info',
    },
    {
      value: 'consignment',
      key: 'consignment',
      name: 'consignment',
      type: 'info',
    },
  ],
}

const getters = {
  getCollaterals: state => state.collaterals,
  getCollateral: state => state.collateral,
  getCollateralByPid: findByKey('collaterals', 'pid'),
}

const mutations = {
  setCollaterals: set('collaterals'),
  setCollateral: set('collateral'),
}

const actions = {
  fetchCollateralsList: async ({ rootState, state, commit }) => {
    const { sid } = rootState.users.user
    if (sid) {
      if (!state.collaterals || state.collaterals.length < 1) {
        collateralsCollection.where('sid', '==', sid).onSnapshot(snapshot => {
          const collaterals = []
          if (snapshot.empty) {
            commit('setCollaterals', [])
          } else {
            snapshot.forEach(doc => {
              const d = doc.data()
              d.createdAt = d.createdAt.toDate()

              collaterals.push({ ...d, pid: doc.id })
            })

            commit('setCollaterals', collaterals)
          }
        }, err => {
          throw new Error(err)
        })
      }
    }
  },
  fetchProduct: async ({
    rootState, getters, state, commit,
  }, { pid }) => new Promise((resolve, reject) => {
    const { sid } = rootState.users.user
    if (sid && pid) {
      if (getters.getCollateralByPid(pid)) {
        commit('setCollateral', getters.getCollateralByPid(pid))
        resolve(getters.getCollateralByPid(pid))
      } else {
        const unsubscribe = collateralsCollection.doc(pid).onSnapshot(snapshot => {
          if (snapshot.exists) {
            commit('setCollateral', { ...snapshot.data(), pid: snapshot.id })
            if (state.collaterals.length > 0) unsubscribe()
            resolve({ ...snapshot.data(), pid: snapshot.id })
          } else {
            commit('setCollateral', null)
          }
        }, err => {
          reject(err)
        })
      }
    } else {
      reject(new Error(`Missing Params sid: ${sid}, pid: ${pid}`))
    }
  }),
  createProduct: async ({ rootState, dispatch }, params) => {
    const { uid, sid } = rootState.users.user
    if (uid && sid) {
      const collateralsRef = collateralsCollection.doc()
      const pid = collateralsRef.id

      const update = {
        sid,
        productName: params.productName || '',
        productType: params.productType || '',
        productBrand: params.productBrand || '',
        productModel: params.productModel || '',
        productYear: params.productYear || '',
        productAppraisal: params.productAppraisal || '',
        location: params.location || '',
        status: params.status || '',
        collateralDate: params.collateralDate || '',
        foreclosureDate: params.foreclosureDate || '',
        loanAmount: params.loanAmount || '',
        interestRate: params.interestRate || '',
        customer: params.customer || {},
        OtherInformation: params.otherInformation || '',
        productImages: [],
        lastModify: uid,
        createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (params.productImages.length > 0) {
        let num = 1
        const imagePromises = []
        params.productImages.forEach(image => {
          let name = pid
          if (image.default === 0) {
            name += `-${num}`
            num += 1
          }
          const response = dispatch('uploadProductImage', { sid, blob: image.blob, name }).then(file => {
            const data = {
              ...file,
              default: image.default,
            }

            if (image.default === 1) {
              update.productImages.unshift(data)
            } else {
              update.productImages.push(data)
            }
          })
          imagePromises.push(response)
        })
        await Promise.all(imagePromises)
      }
      collateralsRef.set(update)
    } else {
      throw new Error('Missing Params')
    }
  },
  uploadProductImage: async (ctx, {
    sid, blob, name, pid,
  }) => new Promise((resolve, reject) => {
    if (sid && blob && name) {
      const extension = blob.type.replace('image/', '')
      const metadata = {
        customMetadata: {
          id: pid,
        },
        contentType: blob.type,
      }

      const storageRef = firebase.storage.ref()
      const mountainsRef = storageRef.child(`collaterals/${sid}/${name}.${extension}`)

      mountainsRef.put(blob, metadata).then(async snapshot => {
        if (snapshot.state === 'success') {
          const urlRef = storageRef.child(`collaterals/${sid}/${name}.${extension}`)
          urlRef.getDownloadURL().then(url => {
            resolve({ url, name: `${name}.${extension}` })
          })
        } else {
          resolve(null)
        }
      }).catch(error => {
        reject(error)
      })
    }
  }),
}

export default {
  state,
  mutations,
  getters,
  actions,
}
