export function oneDateRange(date) {
  const d = new Date(date)
  const startDate = new Date(d.getFullYear(), d.getMonth(), d.getDate())
  const endDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)
  return { startDate, endDate }
}

export function dateRange() {
}

export function isEmpty(obj) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length
}
