/* CURD Firestore Database */
import axios from 'axios'
import { db, auth } from './firebase'

// record history
export const recordHistory = async ({
  collectionName, action, actionId, ...history
}) => {
  await auth.currentUser.getIdTokenResult(true).then(async token => {
    const { sid } = token.claims
    if (sid) {
      const response = await axios.get('https://api.ipify.org?format=json')
      const userIp = response.data.ip
      const log = {
        type: collectionName,
        action,
        actionId,
        data: history,
        uid: auth.currentUser?.uid,
        sid,
        createdAt: new Date(),
      }
      if (userIp) log.userIp = userIp
      const result = await db.collection('stores').doc(sid).collection('logs').add(log)
      return result
    }
    return null
  })
  return null
}

// set any doc
export const setOneDoc = async ({
  collectionName,
  id,
  noLogging = false,
  ...doc
}) => {
  const result = await db
    .collection(collectionName)
    .doc(id)
    .set({ ...doc, updatedAt: new Date() })
  if (!noLogging) {
    await recordHistory({
      collectionName, action: 'set', actionId: id, ...doc,
    })
  }
  return result
}

// add any doc
export const addOneDoc = async ({
  collectionName,
  id,
  noLogging = false,
  ...doc
}) => {
  const result = await db.collection(collectionName).add({ ...doc, createdAt: new Date(), updatedAt: new Date() })
  if (!noLogging) {
    await recordHistory({
      collectionName,
      action: 'add',
      actionId: result.id,
      ...doc,
    })
  }
  return result
}

// update any doc
export const updateOneDoc = async ({
  collectionName,
  id,
  noLogging = false,
  ...doc
}) => {
  const result = await db.collection(collectionName).doc(id).update({ ...doc, updatedAt: Date() })
  if (!noLogging) {
    await recordHistory({
      collectionName,
      action: 'update',
      actionId: id,
      ...doc,
    })
  }
  return result
}

// delete any doc
export const deleteOneDoc = async ({ collectionName, id, noLogging = false }) => {
  const result = await db.collection(collectionName).doc(id).delete()
  if (!noLogging) {
    await recordHistory({ collectionName, action: 'delete', id })
  }
  return result
}

// get any doc by id
export const getOneDoc = async ({ collectionName, id }) => {
  const result = await db.collection(collectionName).doc(id).get()
  return result
}

// get all docs
export const getAllDocs = async ({ collectionName }) => {
  const result = await db.collection(collectionName).get()
  return result
}

// get where docs
export const getWhereEqualDocs = async ({ collectionName, fieldName, value }) => {
  const result = await db.collection(collectionName).where(fieldName, '==', value).get()
  return result
}
